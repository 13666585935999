.Home .action-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Home .survey-listing-container {
  overflow-x: visible;
}

.Home .survey-listing-container .nav {
  margin-top: -40px;
  justify-content: flex-end;
}

.Home .survey-listing-container .nav .nav-link {
  color: #ffffff;
}

.Home .survey-listing-container .nav .nav-link:hover {
  background-color: #007bff;
  transition: background-color 0.15s ease-in-out;
}

.Home .survey-listing-container .nav .nav-link.active {
  background-color: #355d89;
}
