.protected-icon {
  vertical-align: middle;
  font-size: 96px;
  margin-right: 16px;
  padding-bottom: 32px;
}

.Authenticate .form-group {
  margin-top: 32px;
}
