span.left-label {
  margin-right: 3px;
}

span.right-label {
  margin-left: 3px;
}

.br-wrapper {
  display: inline-block;
}
