.SurveyVernacularEditor textarea {
  font-family: monospace;
  font-size: 1em;
}

.SurveyVernacularEditor .vernacular-container {
  overflow-x: visible;
}

.SurveyVernacularEditor .btn-group {
  margin-top: -38px;
}
