.navbar-brand {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Baskerville, Baskerville Old Face, Hoefler Text, Garamond,
    Times New Roman, serif;
  height: 80px;
}

.bg-dark {
  background-color: #2a2c2e !important;
}

.navbar-brand .box {
  background-color: #355d89;
  padding: 10px 16px;
}

.btn-primary {
  background-color: #355d89;
  border-color: #355d89;
}
.btn-primary:hover {
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary {
  color: #355d89;
  border-color: #355d89;
}
.btn-outline-primary:hover {
  border-color: #007bff;
}
.btn-info {
  background-color: #0a9c7f;
  border-color: #0a9c7f;
}
.btn-info:hover {
  background-color: #0cbc98;
  border-color: #0cbc98;
}

.sv_main .sv_custom_header {
  display: none; /* Remove weird gray background header */
}

.sv_q input[type="radio"]:focus {
  outline: none !important; /* Remove weird outline on focused radio boxes */
}

.sv_main .sv_body {
  border: none; /* Remove border at the bottom of the header */
}

.jumbotron {
  background-color: #0a9c7f;
  color: #ffffff;
  border-radius: 0;
  margin-bottom: 0;
}

a {
  color: #355d89;
}

/* .svd_container
  .svd_content
  .svd_survey_designer
  .svd_editors
  .svd_questions_editor,
.svd_container .svd_properties .svd_object_editor {
  height: 48vh;
} */

#root {
  position: relative;
  min-height: 100%;
  padding-bottom: 120px;
}

.site-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  background-color: #1a1c1e;
  color: #9a9c9e;
  text-transform: uppercase;
  font-size: small;
  font-family: "Roboto Condensed", Helvetical, Arial, sans-serif;
}
