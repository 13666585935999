.snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #dc3545;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 1rem;
}

.snackbar.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 4.6s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
